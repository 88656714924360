<style>
</style>

<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="coreId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Reportes</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="2" md="2" lg="2">
                                                ID <span class="primary--text">
                                                    {{ item.coreId}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="10" md="10" lg="10">
                                                NOMBRE <span class="green--text"> {{item.coreNombre}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                       <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="success" class="mr-2" v-on="on" @click.stop="dialog2 = true" :to="`/sistema/reportes/view/${item.coreId}`">
                                                    <v-icon>mdi-table-search</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Consultar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="purple" v-on="on" @click.stop="dialog2 = true" @click="editar(item.coreId)">
                                                    <v-icon>mdi-file-chart</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Ejecutar</span>
                                        </v-tooltip>

                                    </v-col>
                                </v-row>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="ejecutar">
            <v-dialog v-model="dialog2" persistent width="1000px" max-width="1000px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ form.coreNombre }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="form.tblConfReportesparametros.length >0">
                                <v-col cols="12" sm="12" md="4" v-for="(items,i) in form.tblConfReportesparametros" :key="i">
                                    <label :for="items.corpId+items.corpNombre">{{items.corpLabel}}</label>
                                    <template v-if="items.tipo.comaNombrecorto == 'TEXT'">
                                        <v-text-field :id="items.corpId+items.corpNombre" outlined v-model="items.Valor"></v-text-field>
                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'TEXTAREA'">
                                        <v-textarea :id="items.corpId+items.corpNombre" outlined v-model="items.Valor"></v-textarea>
                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'LISTA'">
                                        <v-autocomplete v-if="items.corpConsulta && listasParametros.length>0" :id="items.corpId+items.corpNombre" outlined v-model="items.Valor" :items="listasParametros.find(e => e.corpId ==items.corpId).lista" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>
                                        <v-autocomplete v-else :id="items.corpId+items.corpNombre" outlined v-model="items.Valor" :items="getValues(items.corpValor)" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>

                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'SI/NO'">
                                        <v-switch :id="items.corpId+items.corpNombre" v-model="items.Valor" :true-value="items.corpValor.split(';')[0]" :false-value="items.corpValor.split(';')[1]"></v-switch>
                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'FECHA'">
                                        <v-menu v-model="items.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field required outlined v-model="items.Valor" append-icon="event" readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker locale="ES" v-model="items.Valor" @input="items.menu = false"></v-date-picker>
                                        </v-menu>
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" sm="12" md="12">
                                    <v-alert text prominent dense type="info">
                                        Este reporte no cuenta con parametros, puedes generarlo.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" :loading="loader" dark @click="ejecutarExcel">Generar excel</v-btn>
                        <v-btn color="primary darken-1" :loading="loader" dark @click="ejecutar">Generar cvs</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </v-container>
</template>

<script>
import {
  saveAs
} from 'file-saver';
import converter from 'json-2-csv';

export default {

  data() {
    return {

      dialog2: false,
      loader: false,
      Count: 0,
      page: 1,
      pageCount: 0,

      url: "reporte/reportes/",
      token: this.$cookies.get("token"),

      listasParametros: [],
      form: {
        coreId: null,
        coreNombre: null,
        coreConsulta: '',
        tblConfReportesparametros: []
      },
      filtros: [{
          text: "Nombre",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false

        },

      ],
      lista: [],
      listaCopia: [],
      tipoparametros: [],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created: async function() {
      this.$store.commit("setCargaDatos", true);
      await this.buscar(this.filtros);
      this.$store.commit("setCargaDatos", false);
  },

  methods: {

    async search(searchP) {
      this.$store.commit("setCargaDatos", true);
      const data = await this.$apiService.index(`${this.url}index?${searchP}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit("setCargaDatos", false);
    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page - 1) * 10}&`;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `coreNombre=${this.filtros[0].value}&`;
      }
      search = search + `coreEstado=VISIBLE&`;
      if (search != "") {
        await this.search(search);
      }
    },
    getValues(values) {
      const lista = [];

      var datos = values.split('|');
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(';')[0],
          values: d.split(';')[1]
        });
      }
      return lista;

    },
    async editar(id) {
      try {
          let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          if (data) {
            this.form = data;
            if (!this.form.tblConfReportesparametros) {
              this.form.tblConfReportesparametros = [];
            } else {
              this.form.tblConfReportesparametros.forEach(async o => {
                o.Valor = null;
                o.menu = false;
                if (o.corpConsulta) {
                  let lista = await this.$apiService.create(this.url + "consultar/", {
                    consulta: o.corpConsulta
                  }).then(data => data);
                  this.listasParametros.push({
                    corpId: o.corpId,
                    lista: lista
                  });
                }

              });
            }
          this.isNew = false;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },

    async ejecutar() {
      this.loader = true;
      let valido = true;
      /*this.form.tblConfReportesparametros.forEach(o => {
        if (o.Valor == null || o.Valor.toString().replace(/ /g, "") == "") {
          valido = false;
        }
      });*/
      if (valido) {
        try {

            let data = await this.$apiService.create(this.url + "ejecutar/", this.form).then(data => data);
            if (data) {
              const csv = await converter.json2csvAsync(data, {
                prependHeader: true,
                emptyFieldValue:true,
                delimiter: {
                  field: ',',
                },
              });
              var blob = new Blob([csv], {
                type: "text/plain;charset=utf-8"
              });
              saveAs(blob, this.form.coreNombre + ".txt");
            }
          this.close();

        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
      this.loader = false;
    },
    async ejecutarExcel() {
      this.loader = true;
      let valido = true;
    /*  this.form.tblConfReportesparametros.forEach(o => {
        if (o.Valor == null || o.Valor.toString().replace(/ /g, "") == "") {
          valido = false;
        }
      });*/
      if (valido) {
        try {

            var reporte = await this.$apiService.create(this.url + "ejecutar/", this.form).then(data => data);
            import('@/vendor/Export2Excel').then(excel => {

              let head = [{sheet:"Page1",data:Object.keys(reporte[0])}];
              let data = [{sheet:"Page1",data:reporte.map(item => {
                return Object.values(item)
              })}];
              excel.export_json_to_excel({
                header: head, //Header Required
                data, //Specific data Required
                filename: this.form.coreNombre, //Optional
                autoWidth: true, //Optional
                bookType: 'xlsx' //Optional
              })
            });
          this.close();

        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
      this.loader = false;
    },
    limpiar() {
      this.form = {
        coreId: null,

        coreNombre: null,
        coreConsulta: '',
        tblConfReportesparametros: []
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.dialog2 = false;
      this.limpiar();
    }
  }
};

</script>
